const SGT = {
    position: 'Senior Frontend Developer',
    tab: 'SGT Digital Solutions',
    company: 'SGT Digital Solutions',
    workStart: 'Mar. 2022',
    workUntil: '<span class="present-work">Present</span>',
    des: "Full Time <b>Senior Frontend Developer</b> at <a href='https://sgt.com.eg/en/' target='_blank'>SGT</a>. Responsibilities are Support Live issues and hotfixes, Integrate with different systems and Supervision of the system, construction and team.",
    url: 'https://sgt.com.eg/en/',
};

const DigitalVeins = {
    position: 'Mid Frontend Developer',
    tab: 'Digital Veins',
    company: 'Digital Veins',
    workStart: 'Jan. 2021',
    workUntil: 'Mar. 2022',
    des: "Full Time <b>Mid Frontend Developer</b> at <a href='https://www.linkedin.com/company/digital-veins/' target='_blank'>Digital Veins</a>. Responsibilities are recommending solutions for a streamlined, user-friendly interface with the design team, Act as a senior, performing bug fixes and code reviews and Customize design and implement it with statistics info graphs.",
    url: 'https://www.linkedin.com/company/digital-veins/',
};

const iSchool = {
    position: 'Front End Developer',
    tab: 'iSchool Egypt',
    company: 'iSchool Egypt',
    workStart: 'June. 2020',
    workUntil: 'Jan. 2021',
    des: `Part Time <b>Frontend Developer</b> at <a href='https://www.ischooltech.com/' target='_blank'>iSchool Egypt</a>. Responsibilities are Building stable and maintainable codebases using Vue.js & Bootstrap and Handle many cycles between admin panel, sales panel and user and panel and Build and handle new way for enroll requests and payment, methods between students and system`,
    url: 'https://www.ischooltech.com/',
};

const eduValy = {
    position: 'Front End Developer',
    tab: 'EduValy',
    company: 'EduValy',
    workStart: 'July. 2020',
    workUntil: 'Jan. 2021',
    des: `Full Time <b>Frontend Developer</b> at <a href='https://web.facebook.com/eduvalyOfficia' target='_blank'>EduValy</a>. Responsibilities are Designing and implementing Web Builder Module and  Improve interface of dashboard and cycles of route guards Convert all components, pages and colors to best practices (usable - readable)`,
    url: 'https://web.facebook.com/eduvalyOfficia',
};

const Helpee = {
    position: 'Front End Developer',
    tab: 'Helpee',
    company: 'Helpee',
    workStart: 'Apr. 2019',
    workUntil: 'Fep. 2020',
    des: `Full Time <b>Frontend Developer</b> at <a href='https://www.linkedin.com/company/helpee-app/' target='_blank'>Helpee</a>. Responsibilities are Building and designing from scratch Website and admin panel Handel operations between helpers and people with disabilities`,
    url: 'https://www.linkedin.com/company/helpee-app/',
};
export default [SGT, DigitalVeins, iSchool, eduValy, Helpee];
